.leaflet-div-icon {
    background: none !important;
    border: none !important;
}

.cluster-marker {
    color: #fff;
    background: #1978c8;
    border-radius: 50%;
    padding: 10px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cluster-site-marker {
    color: #fff;
    background: #ffb006;
    border-radius: 50%;
    padding: 10px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}